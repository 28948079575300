import axios from '@axios'

export default {
  async getVorlagen(vkey) {
    try {
      const r = await axios.get('vorlage/getVorlage', {
        params: {
          vkey,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getVorlagenDatei(vkey, mandantID, sprache) {
    mandantID ??= 0 // eslint-disable-line
    try {
      const r = await axios.get('vorlage/getVorlageDatei', {
        responseType: 'blob',
        headers: { Accept: 'application/pdfapplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
        params: {
          vkey,
          mandantID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getVorlagenTransExcel(vkey, mandantID) {
    mandantID ??= 0 // eslint-disable-line
    try {
      const r = await axios.get('vorlage/getVorlageTransExcel', {
        responseType: 'blob',
        headers: { Accept: 'application/pdfapplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
        params: {
          vkey,
          mandantID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async postVorlage(datei, vkey, vorlageID, mandantID, sprache, vorlageMandantID) {
    mandantID ??= 0 // eslint-disable-line
    vorlageMandantID ??= 0 // eslint-disable-line
    const formData = new FormData()
    try {
      formData.append('vorlage', datei)
      const r = await axios.post('vorlage/postVorlage', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          vkey,
          vorlageID,
          mandantID,
          sprache,
          vorlageMandantID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async postVorlageTransExcel(datei, vkey, mandantID, vorlageMandantID, vorlageID) {
    mandantID ??= 0 // eslint-disable-line
    const formData = new FormData()
    try {
      formData.append('vorlage', datei)
      const r = await axios.post('vorlage/postVorlageTransExcel', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          vkey,
          mandantID,
          vorlageMandantID,
          vorlageID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getVorlagenMandant(mandantID, onlyCustom) {
    mandantID ??= 0 // eslint-disable-line
    try {
      const r = await axios.get('vorlage/getVorlageMandant', {
        params: {
          mandantID,
          onlyCustom,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async postVorlagenMandantStandardUpd(vorlageMandantID, useStandard) {
    try {
      const r = await axios.get('vorlage/getVorlageMandantStandardUpd', {
        params: {
          vorlageMandantID,
          useStandard,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
}
