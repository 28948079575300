<template>
  <div v-if="!items">Loading Please wait...
  </div>
  <div v-else>
    <b-form>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="items"
        primary-key="VorlageID"
        :fields="fields"
        selectable
        select-mode="single"
        @row-selected="onRowSelected"
      />

      <b-row>
        <b-col
          cols="12"
          md="6 mb-1 text-center"
        >
          <strong>{{ selRow.VKey }}</strong>
        </b-col>

        <b-col
          cols="12"
          md="6 mb-1 text-center"
        >
          <strong>{{ selRow.Name }}</strong>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          md="4 mb-1 text-center"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="downloadDE"
          >
            Download Deutsch
          </b-button>
        </b-col>

        <b-col
          cols="12"
          md="4 mb-1 text-center"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="downloadFR"
          >
            Download Französisch
          </b-button>
        </b-col>

        <b-col
          cols="12"
          md="4 mb-1 text-center"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="downloadTransExcel"
          >
            Download TransExcel
          </b-button>
        </b-col>

      </b-row>

      <b-row>
        <b-col
          cols="12"
          md="4 text-center"
        >
          <div class="w-100 text-center mb-1">
            <b-form-file
              v-model="fileDE"
              :state="Boolean(fileDE)"
              placeholder="Deutsche Vorlage auswählen oder hier ablegen..."
              drop-placeholder="Datei hier ablegen..."
            />
          </div>
          <b-button
            variant="primary"
            class="mb-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="uploadDE"
          >
            Upload Deutsch
          </b-button>
        </b-col>

        <b-col
          cols="12"
          md="4 text-center"
        >
          <div class="w-100 text-center mb-1">
            <b-form-file
              v-model="fileFR"
              :state="Boolean(fileFR)"
              placeholder="Französische Vorlage auswählen oder hier ablegen..."
              drop-placeholder="Datei hier ablegen..."
            />
          </div>
          <b-button
            variant="primary"
            class="mb-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="uploadFR"
          >
            Upload Französisch
          </b-button>
        </b-col>

        <b-col
          cols="12"
          md="4 text-center"
        >
          <div class="w-100 text-center mb-1">
            <b-form-file
              v-model="fileTE"
              :state="Boolean(fileTE)"
              placeholder="TransExcel auswählen oder hier ablegen..."
              drop-placeholder="Datei hier ablegen..."
            />
          </div>
          <b-button
            variant="primary"
            class="mb-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="uploadTransExcel"
          >
            Upload TransExcel
          </b-button>
        </b-col>

      </b-row>

    </b-form>
  </div>

</template>

<script>
/* eslint-disable global-require */
import {
  BTable, BFormFile, BButton, BForm, BRow, BCol,
} from 'bootstrap-vue'
import db from '@/service/vorlage'

export default {
  components: {
    BTable,
    BFormFile,
    BButton,
    BForm,
    BRow,
    BCol,
  },
  data() {
    return {
      selRow: null,
      fileFR: null,
      fileDE: null,
      fileTE: null,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'VKey', label: 'Key' },
        { key: 'Beschrieb', label: 'Beschrieb' },
      ],
      /* eslint-disable global-require */
      items: null,
    }
  },
  async mounted() {
    const r = await db.getVorlagen('')
    if (r.data.length > 0) {
      this.items = r.data
      this.totalRows = this.items.length
      this.selRow = this.items[0]
    }
  },
  methods: {
    onRowSelected(items) {
      if (items.length === 1) this.selRow = items[0]
    },
    async downloadDE() {
      const r = await db.getVorlagenDatei(this.selRow.VKey, 0, 'de')
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', this.selRow.Name)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async downloadFR() {
      const r = await db.getVorlagenDatei(this.selRow.VKey, 0, 'fr')
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', this.selRow.NameFR)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async downloadTransExcel() {
      const r = await db.getVorlagenTransExcel(this.selRow.VKey, 0)
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', this.selRow.Name.replace('.docx', '.xlsx'))
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async uploadDE() {
      if (this.fileDE === null) return
      await db.postVorlage(this.fileDE, this.selRow.VKey, 0, 0, 'de', 0)
    },
    async uploadFR() {
      if (this.fileFR === null) return
      await db.postVorlage(this.file, this.selRow.VKey, 0, 0, 'fr', 0)
    },
    async uploadTransExcel() {
      if (this.fileTE === null) return
      await db.postVorlageTransExcel(this.fileTE, this.selRow.VKey, 0, 0, 0)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
